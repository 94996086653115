<template>
  <div class="all_home_top">
    <div class="home_top">
      <div class="left_box">
        <div class="menu" @click="chooseMenu">
          <img src="@/assets/new_home_icons/menu.png" />
        </div>
        <div class="small_logo" @click="toHomePage">
          <img src="@/assets/new_login/small_logo.png" />
        </div>
      </div>
      <div class="search">
        <div class="search_left">
          <img
            src="@/assets/new_home_icons/search.png"
            style="width: 16px; height: 16px; margin-right: 8px"
          />
          <input
            type="text"
            :placeholder="$t('home.pleaseEnterKeyword')"
            v-model="search_name"
            @keyup.enter="searchClick"
            style="font-size: 16px"
          />
        </div>
        <div class="search_right" @click="searchClick">
          <!-- <img src="@/assets/home_icons/search.png"> -->
          <div>{{ $t("home.search") }}</div>
        </div>
      </div>
      <div class="right_box">
        <!-- <div class="address">
					<div class="img">
						<img src="@/assets/new_home_icons/address.png">
					</div>
					<div class="address_name">新加坡</div>
					<i class="el-icon-arrow-down el-iconright" @click="chooseAddress"
						:class="showAddress ? 'xuanzhuan' : 'xuanzhuanclose'"></i>
				</div> -->

        <div class="applyAd" @click="goApplyAd">
          <!-- <div class="img">
						<img src="@/assets/new_home_icons/release.png">
					</div> -->
          <div class="text">{{ $t("order.applyAd") }}</div>
        </div>

        <div class="release" @click="toReleaseClick">
          <div class="img">
            <img src="@/assets/new_home_icons/release.png" />
          </div>
          <div class="text">{{ $t("other.release") }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="dialog_box" v-if="showAddress">
			<div class="check_box">
				<el-checkbox-group v-model="checkList" class="check">
					<el-checkbox label="北京" class="check_item"></el-checkbox>
					<el-checkbox label="上海" class="check_item"></el-checkbox>
					<el-checkbox label="深圳" class="check_item"></el-checkbox>
					<el-checkbox label="南京" class="check_item"></el-checkbox>
				</el-checkbox-group>
			</div>
		</div> -->

    <!-- 左上角的分类弹窗 -->
    <div class="mengban" @click="showMenu = false" v-if="showMenu"></div>
    <div class="dialog_menu" v-if="showMenu">
      <div class="dialog_menu_box">
        <div class="left">
          <ul>
            <li
              class="li_box"
              :class="check_menu ? 'active_menu' : ''"
              v-for="(item, index) in classifyList"
              :key="index"
              @click="chooseClassifyList(item, index)"
            >
              <div class="left_box">
                <img
                  :src="item.thumb"
                  alt=""
                  class="img"
                />
                <span
                  class="name"
                  :class="chooseIndex == index ? 'is_active' : ''"
                  >{{ lang == "zh" ? item.zh_name : item.bnm_name }}</span
                >
              </div>
              <img
                src="../assets/new_home_icons/active.png"
                alt=""
                class="active"
                v-if="chooseIndex == index"
              />
              <img
                src="../assets/new_home_icons/no_active.png"
                alt=""
                class="active"
                v-else
              />
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="right_left">
            <div
              v-for="(item, index) in classifyList_right"
              :key="index"
              @click="chooseClassifyList_right(item, index)"
              class="right_item"
            >
              <span
                class="name"
                :class="chooseRightIndex == index ? 'is_active' : ''"
                >{{ lang == "zh" ? item.zh_name : item.bnm_name }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/common/function/bus.js";

export default {
  data() {
    return {
      search_name: "",
      isBindingModal: "", //是否完善信息
      showAddress: false, // 定位弹窗
      showMenu: false, // 定位弹窗
      checkList: ["选中且禁用", "复选框 A"],
      check_menu: false, //菜单acitve
      classifyList: [], //所有分类列表
      inforData: [], //二级分类列表
      isHasAddress: [],
      classifyList_right: [],
      chooseIndex: "",
      chooseRightIndex: "",

      chooseId: "", //12新地产，3车

      lang: "",

      father_zh_name: "",
      father_bnm_name: "",
    };
  },
  created() {
    // 实现监听localstorage中某个键对应的值的变化
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", (e) => {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值
      if (e.key === "lang") {
        this.lang = e.newValue;
      }
    });
  },
  mounted() {
    this.search_name = this.$route.query.search_name;
    bus.$on("personInforEmit", (data) => {
      this.getUserInfo();
    });
    this.getUserInfo();
  },
  methods: {
    typeClicks() {
      this.$store.commit("changeShowFilter");
    },
    // 获取会员信息
    getUserInfo() {
      this.$http.userInfo().then((res) => {
        if (res.code == 1) {
          // 是否完善
          this.isBindingModal = res.data.authentication == 0 ? true : false;
        }
      });
    },

    // 去搜索
    searchClick() {
      // if (!this.search_name) {
      // 	this.$message.info(this.$t('tips.searchContent'));
      // 	return
      // }

      this.$router.push({
        path: "/newSearchResult",
        query: {
          search_name: this.search_name,
        },
      });
    },
    inputClick() {},
    typeClick() {
      this.$store.commit("changeShowFilter");
    },
	
    // 申请广告位
    goApplyAd() {
      console.log(!this.$store.state.userinfo);
      if (this.$store.state.isShowFilter == true) {
        this.$store.state.isShowFilter = false;
      }
      if (!this.$store.state.userinfo) {
        this.$message.error(this.$t("other.noLoginPleaseToLogin"));
        setTimeout(() => {
          this.$router.push({
            path: "/accountLogin",
          });
        }, 1500);
        return;
      }

      this.$router.push("/applyAd");

    },

    // 发布信息
    toReleaseClick() {
      console.log(!this.$store.state.userinfo);

      if (this.$store.state.isShowFilter == true) {
        this.$store.state.isShowFilter = false;
      }
      if (!this.$store.state.userinfo) {
        this.$message.error(this.$t("other.noLoginPleaseToLogin"));
        setTimeout(() => {
          this.$router.push({
            path: "/accountLogin",
          });
        }, 1500);
        return;
      }

      // if (this.isBindingModal) {
      // 	this.$message.error(this.$t('completeInfor'));
      // 	setTimeout(() => {
      // 		this.$router.push({
      // 			path: '/verifyPage',
      // 			query: {
      // 				curCenterIndex: 0,
      // 			},
      // 		})
      // 	}, 1000)
      // 	return;
      // }

      this.$router.push("/release");
    },
    toHomePage() {
      this.$router.push("/");
    },

    //选则定位
    chooseAddress() {
      if (this.showAddress == false) {
        this.showAddress = true;
      } else {
        this.showAddress = false;
      }
    },

    //打开菜单弹窗
    chooseMenu() {
      if (this.showMenu == false) {
        this.showMenu = true;
        this.getCategoryList();
        this.chooseIndex = 0;
        this.father_zh_name = this.classifyList[0].zh_name;
        this.father_bnm_name = this.classifyList[0].bnm_name;
      } else {
        this.showMenu = false;
      }
    },
    // 获取所有分类
    getCategoryList() {
      this.$http
        .getCate({
          is_hot: 0,
        })
        .then((res) => {
          if (res.code == 1) {
            this.classifyList = res.data;
            this.classifyList_right = res.data[0].children;
            console.log(this.classifyList, 12324);
            let newClassifyList = this.classifyList.map((item) => {
              return {
                zh_name: item.zh_name,
                bnm_name: item.bnm_name,
                label: item.name,
                value: item.id,
                thumb:item.thumb,
                children: item.children.map((item2) => {
                  return {
                    label: item2.name,
                    value: item2.id,
                    zh_name: item2.zh_name,
                    bnm_name: item2.bnm_name,
                  };
                }),
              };
            });
            this.classifyList = JSON.parse(JSON.stringify(newClassifyList));
            console.log(this.classifyList, "所有分类列表");
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 获取分类
    getInforType() {
      this.$http
        .getCateType({
          cate_id: this.cate_id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.inforData = res.data;
            console.log(this.inforData, "this.inforData");
            this.inforData = this.inforData.map((item) => {
              console.log("当前的item", item.type);
              return {
                ...item,
                value: "",
                valueId: item.type_id,
              };
            });
            console.log(this.infoData, 12345);
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    chooseClassifyList(item, index) {
      // this.check_menu = true
      console.log(item, "一级分类");
      this.classifyList_right = item.children;
      this.chooseIndex = index;
      this.chooseId = item.value;
      this.father_zh_name = item.zh_name;
      this.father_bnm_name = item.bnm_name;
      console.log(this.classifyList_right, "二级");
    },

    chooseClassifyList_right(item, index) {
      // 取到小分类的id
      console.log(item, "二级分类");
      this.chooseRightIndex = index;

      if (this.chooseId == 159) {
        this.$router.push({
          path: `/house_classify_leve2`,
          query: {
            classify_id: item.value,
            zh_name: item.zh_name,
            bnm_name: item.bnm_name,
            father_zh_name: this.father_zh_name,
            father_bnm_name: this.father_bnm_name,
          },
        });
      } else if (this.chooseId == 3) {
        this.$router.push({
          path: `/car_classify_leve2`,
          query: {
            classify_id: item.value,
            zh_name: item.zh_name,
            bnm_name: item.bnm_name,
            father_zh_name: this.father_zh_name,
            father_bnm_name: this.father_bnm_name,
          },
        });
      } else {
        this.$router.push({
          path: `/classify_leve2`,
          query: {
            classify_id: item.value,
            zh_name: item.zh_name,
            bnm_name: item.bnm_name,
            father_zh_name: this.father_zh_name,
            father_bnm_name: this.father_bnm_name,
          },
        });
      }

      this.showMenu = false;
    },
  },
};
</script>

<style scoped="" lang="less">
.all_home_top {
  background-color: #ffffff;
}

.home_top {
  // width: 1920px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  border-bottom: 3px solid rgba(243, 152, 0);
}

.left_box {
  display: flex;
  justify-content: left;
  align-items: center;

  .menu {
    margin-right: 120px;
  }
}

.right_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  cursor: pointer;

  img {
    width: 32px;
    height: 26px;
  }
}

.small_logo {
  cursor: pointer;

  img {
    // width: 162px;
    height: 44px;
  }
}

.search {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  width: 700px;
  height: 48px;
  opacity: 1;
  box-sizing: border-box;
  position: relative;

  .search_left {
    width: 460px;
    text-align: left;
    padding-left: 24px;

    input {
      width: 400px;
      border: none;
      outline: none;
      background-color: transparent;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
  }

  .search_right {
    position: absolute;
    right: -2px;
    box-sizing: border-box;
    width: 112px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f39800;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff;
    font-size: 16px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.address {
  // width: 170px;
  display: flex;
  align-items: center;
  margin-right: 40px;

  img {
    width: 24px;
    height: 24px;
  }

  // .img {
  // 	display: flex;
  // 	align-items: center;
  // 	margin-right: 5px;

  // 	img {
  // 		width: 20px;
  // 		height: 20px;
  // 	}
  // }
}

.address_name {
  // padding: 10px 5px;
  margin: 0 24px 0 8px;
  padding: 10px 0px;
  color: #333;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.applyAd {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  box-sizing: border-box;
  width: 150px;
  height: 58px;
  background: blue;
  opacity: 1;
  border-radius: 6px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }

  .text {
    font-size: 16px;
  }
}

.release {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  box-sizing: border-box;
  width: 200px;
  height: 58px;
  background: green;
  opacity: 1;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
  }

  .img {
    margin-right: 8px;
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
    }
  }

  .text {
    font-size: 16px;
  }
}

.dialog_box {
  position: absolute;
  right: 80px;
  top: 100px;
  width: 338px;
  height: 344px;
  background-image: url("../assets/new_home_icons/xiala.png");
  // background-color: pink;
  background-repeat: no-repeat;
  background-size: contain;

  .check_box {
    position: relative;
    width: 100%;
    height: 100%;

    .check {
      display: grid;
      grid-template-columns: 50% 50%;
      margin-top: 50px;
      padding: 0 48px;

      .check_item {
        margin-bottom: 16px;
      }
    }
  }
}

.xuanzhuan {
  transition: all 0.2s;
  transform: rotateZ(180deg);
}

.xuanzhuanclose {
  transition: all 0.2s;
  transform: rotateZ(0deg);
}

.mengban {
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.39);
}

.dialog_menu {
  position: absolute;
  top: 140px;
  left: 0;
  // width: 100%;
  // height: 100vh;
  // background: rgba(0, 0, 0, 0.39);

  .dialog_menu_box {
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 1040px;
    height: 100vh;
    // height: 368px;
    background: rgba(255, 255, 255);

    .left {
      width: 480px;
      height: 100%;
      overflow: auto;
      background-color: #fff;

      .li_box {
        display: flex;
        justify-content: space-between;
        height: 72px;
        align-items: center;
        padding: 24px 32px;
        border-bottom: 1px solid #e6e6e6;
        box-sizing: border-box;
      }

      .active_menu {
        background: rgba(246, 247, 249);
        color: #f39800;
      }

      .left_box {
        display: flex;
        justify-content: space-between;

        .img {
          width: 26px;
          height: 26px;
        }

        .name {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-left: 32px;
        }
      }

      .active {
        width: 16px;
        height: 16px;
      }
    }

    .right {
      background: rgba(246, 247, 249);
      // display: flex;
      // justify-content: left;
      padding-left: 48px;
      width: 660px;
      height: 100%;
      overflow: auto;
      box-sizing: border-box;
      padding-top: 10px;

      .right_left {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;
        text-align: left;

        .right_item {
          width: 200px;
          height: 72px;
          line-height: 72px;
          margin-right: 80px;
          border-bottom: 1px solid #e6e6e6;
          white-space: nowrap;
        }

        .right_item:nth-child(2n) {
          margin-right: 0 !important;
        }
      }

      .name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        // color: #F39800;
      }
    }

    .is_active {
      color: #f39800 !important;
    }
  }

  ul {
    li {
      list-style: none;
    }
  }
}
</style>
